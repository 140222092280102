<template>
    <div class="text-center">
        <v-dialog v-model="local" width="500" persistent>
            <v-card>
                <v-card-title>
                    <p class="title">Select Store</p>
                </v-card-title>
                <v-card-text>
                    <v-alert :value="showAlert" color="error" type="warning">{{ this.alertMessage }}</v-alert>
                    <v-select :items="stores" v-model="selectValue" label="Store"></v-select>
                    <v-text-field label="Password" v-model="storePassword" type="password" @keydown.enter="loginStore"></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" depressed @click="loginStore">
                        Login
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { API } from '@/inc/api';
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        show    : { type: Boolean, default: false }
    },
    data() {
        return {
            local           : this.show,
            stores          : [
                { text: 'San Juan', value: 1, authKey: '0c829d1c-db8d-11e8-ae2e-deadbe115600' },
                { text: '65 de Infanteria', value: 12, authKey: '17dfae08-2015-11ea-ad06-12761e074d50' },
                { text: 'Arecibo', value: 11, authKey: '53f3c02b-a7ce-11e9-bbda-124bb3274b88' },
                { text: 'Bayamón', value: 3, authKey: 'eb256f74-db8d-11e8-ae2e-deadbe115600' },
                { text: 'Caguas', value: 2, authKey: 'ead62ce1-db8d-11e8-ae2e-deadbe115600' },
                { text: 'Cataño', value: 13, authKey: '485e2d78-af31-11ea-a881-12761e074d50' },
                { text: 'Mayaguez', value: 4, authKey: 'eb7af6c8-db8d-11e8-ae2e-deadbe115600' },
                { text: 'Ponce', value: 5, authKey: 'ebc8ca60-db8d-11e8-ae2e-deadbe115600' },
                { text: 'Santo Domingo', value: 6, authKey: '964ca5f1-44f3-11e9-ae2e-deadbe115600' },
                { text: 'Winter Park', value: 9, authKey: '935f4c69-4730-11e9-ae2e-deadbe115600' },
                { text: 'Plaza las Americas', value: 14, authKey: 'ed952d75-6d57-11ef-9dc9-0242ac130002' },
            ],
            selectValue     : '',
            storePassword   : '',
            showAlert       : false,
            alertMessage    : '',
        }
    },
    computed: {
        ...mapGetters({
            store       : 'store',
        }),
        form() {
            return {
                storeId: this.selectValue,
                password: this.storePassword
            }
        },
    },
    watch: {
        show(v) {
            this.local = v;
        }
    },
    methods: {
        ...mapActions({
            updateStore     : 'updateStore',
            load            : 'loadFromLocalStorage',
        }),
        async loginStore() {
            this.showAlert = false;
            this.alertMessage = '';
            
            if( this.selectValue != '' && this.storePassword != '') {
                try {
                    let api = new API();

                    this.processing = true;
                    let res = await api.post( '/valstr', this.form );

                    if( res.data.status !== true ) {
                        this.alertMessage = res.data.message;
                        this.showAlert = true;
                        return;
                    }

                    let key = this.stores.find( s => s.value == this.selectValue ).authKey;
                    
                    var obj = { value: this.selectValue, key, timeStamp: new Date().getTime() }
                    localStorage.setItem("m2m-selected-store", JSON.stringify(obj));
                    this.updateStore({ prop: 'store', payload: obj.value });
                    this.updateStore({ prop: 'storeDialog', payload: false });
                    this.updateStore({ prop: 'isClubman', payload: res.data.isClubman });

                    this.storePassword = '';
                    this.selectValue = '';
                    this.$emit('loggedin');
                    return;
                }
                catch( error ) {
                    console.log(error);
                }
                finally {
                    this.processing = false;
                }
            }
            else
                alert('You must select a store and enter the password before login in.');
        },
    }
}
</script>

<style lang="scss" scoped>

</style>